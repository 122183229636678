import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  CrudOperationType,
  FileOperationDownloadRequest,
  FileOperationGenerateZipRequest,
  FileOperationInfo,
  FileOperationMoveMultipleFilesRequest,
  FileOperationRemoveMultipleFilesRequest,
  FileOperationRemoveRequest,
  FileOperationType,
  FileOperationUploadRequest,
  SafariReduxFileTransferObjectDefinition,
  reduxActionFail
} from '@safarilaw-webapp/shared/common-objects-models';
import { CrudGenericService, FileTransferService } from '@safarilaw-webapp/shared/crud';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { ErrorMessageParserService } from '@safarilaw-webapp/shared/error-handling-message-parser';
import { FAILED_OBJECT_FILE, FailedObjectsService } from '@safarilaw-webapp/shared/failed-objects';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { ObjectFileTransferServiceBase } from '@safarilaw-webapp/shared/redux';
import { childError } from '@safarilaw-webapp/shared/utils';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

enum UploadType {
  New = 0,
  Update = 1
}
export class ObjectFileTransferService extends ObjectFileTransferServiceBase {
  constructor(
    errorParserService: ErrorMessageParserService,
    crudGenericService: CrudGenericService,
    protected _fileTransferService: FileTransferService,
    appConfig: AppConfigurationService,
    store: Store<any>,
    actions: Actions,
    fileTransferObject: SafariReduxFileTransferObjectDefinition,
    failedObjectsService: FailedObjectsService,
    protected _loggerService: LoggerService
  ) {
    super(store, actions, fileTransferObject, crudGenericService, errorParserService, failedObjectsService, appConfig);
  }
  private _remove(payload: FileOperationRemoveRequest) {
    const attachmentAssociateUrl = this.getRemoveUrl(payload);
    const info = {
      ...payload,
      ...{ secondsUntilTransferDialogShown: payload.secondsUntilTransferDialogShown },
      ...{
        message: 'Removing',
        fileOperationType: FileOperationType.Remove,
        isError: false,
        percentComplete: 0,
        downloadLink: null
      }
    } as FileOperationInfo;
    this._store.dispatch(this._fileTransferObject.default.actions.updateFileUploadProgress({ payload: info }));
    const abort$ = super.getRemoveAbortPipe(payload.fileName, payload.actionId, payload);
    const request = this._crudGenericService.delete(attachmentAssociateUrl + payload.id, null).pipe(
      map(() => {
        const result = {
          ...payload,
          ...{ secondsUntilTransferDialogShown: payload.secondsUntilTransferDialogShown },
          ...{
            message: 'Removed',
            isError: false,
            percentComplete: 100,
            fileOperationType: FileOperationType.Remove,
            downloadLink: null
          }
        } as FileOperationInfo;
        this._store.dispatch(this._fileTransferObject.default.actions.updateFileUploadProgress({ payload: result }));

        return result;
      }),

      takeUntil(abort$),

      childError(
        this._failedObjectsService,
        this._loggerService,
        CrudOperationType.Delete,
        FAILED_OBJECT_FILE,
        {
          ...payload
        },
        true
      )
    );
    return request;
  }
  remove(payload: FileOperationRemoveRequest) {
    return this._remove(payload);
  }
  removeMultiple(payload: FileOperationRemoveMultipleFilesRequest) {
    return this._removeMultiple(payload);
  }
  moveMultiple(payload: FileOperationMoveMultipleFilesRequest) {
    const subfolders = payload.fileRequests.filter(o => o.isSubfolder);
    const files = payload.fileRequests.filter(o => !o.isSubfolder);
    if (files.length && subfolders.length) {
      throw new Error('File and subfolder move can not be in the same request. ');
    }
    if (subfolders.length) {
      return this._moveMultiple(payload, true);
    } else {
      return this._moveMultiple(payload, false);
    }
  }
  private _moveMultiple(payload: FileOperationMoveMultipleFilesRequest, isSubfolder) {
    const attachmentAssociateUrl = isSubfolder ? this.getMoveMultipleFoldersUrl(payload) : this.getMoveMultipleFilesUrl(payload);

    payload.fileRequests.forEach(fileRequest => {
      const info = {
        ...fileRequest,
        ...{ secondsUntilTransferDialogShown: fileRequest.secondsUntilTransferDialogShown },
        ...{
          message: 'Moving',
          fileOperationType: FileOperationType.Move,
          isError: false,
          percentComplete: 0,
          downloadLink: null
        }
      } as FileOperationInfo;

      this._store.dispatch(this._fileTransferObject.default.actions.updateFileUploadProgress({ payload: info }));
    });

    const abort$ = super.getMoveMultipleFilesAbortPipe(payload.fileName, payload.actionId, payload);

    const requestPayload = isSubfolder
      ? {
          attachmentFolders: payload.fileRequests.map(o => ({
            attachmentFolderId: o.id,
            destinationType: payload.folder,
            destinationFolderId: payload.subfolder
          }))
        }
      : {
          attachments: payload.fileRequests.map(o => ({
            attachmentId: o.id,
            destinationType: payload.folder,
            destinationFolderId: payload.subfolder
          }))
        };
    const request = this._crudGenericService
      ._put(
        {
          __etag: payload.metadata.etag,
          ...requestPayload
        },
        attachmentAssociateUrl,
        null
      )
      .pipe(
        map(() => {
          payload.fileRequests.forEach(fileRequest => {
            const result = {
              ...fileRequest,
              ...{ secondsUntilTransferDialogShown: fileRequest.secondsUntilTransferDialogShown },
              ...{
                message: 'Moved',
                isError: false,
                percentComplete: 100,
                fileOperationType: FileOperationType.Move,
                downloadLink: null
              }
            } as FileOperationInfo;
            this._store.dispatch(this._fileTransferObject.default.actions.updateFileUploadProgress({ payload: result }));
          });
          // Usually we return the original payload of the individual file but this is an action with array of file requests,
          // so we can't do that. It should be safe to return NULL here though - we don't really do anything meaningful with the result
          // (we just store it as "payload" of success action)
          return of(null);
        }),

        takeUntil(abort$),
        // This is slighlty modified code from childError custom rxjs operator.
        // We can't use child error as its supposed to operate on one request but this is a
        // compound request consisting of many deletions. So  instead we'll just borrow some of that code,
        // and tweak it a bit to add failed files
        catchError(error => {
          for (const fileRequest of payload.fileRequests) {
            this._failedObjectsService.addFailedObject(fileRequest.objectTypeName ? fileRequest.objectTypeName : FAILED_OBJECT_FILE, {
              originalContent: fileRequest.originalContent,
              error,
              operation: CrudOperationType.Update
            });
          }
          return throwError(() => error);
        })
      );
    return request;
  }

  private _removeMultiple(payload: FileOperationRemoveMultipleFilesRequest) {
    const attachmentAssociateUrl = payload.useQueryString ? this.getRemoveMultipleFilesUrl(payload) : this.getRemoveUrl(payload);

    payload.fileRequests.forEach(fileRequest => {
      const info = {
        ...fileRequest,
        ...{ secondsUntilTransferDialogShown: fileRequest.secondsUntilTransferDialogShown },
        ...{
          message: 'Removing',
          fileOperationType: FileOperationType.Remove,
          isError: false,
          percentComplete: 0,
          downloadLink: null
        }
      } as FileOperationInfo;

      this._store.dispatch(this._fileTransferObject.default.actions.updateFileUploadProgress({ payload: info }));
    });

    const abort$ = super.getRemoveMultipleFilesAbortPipe(payload.fileName, payload.actionId, payload);

    const request = this._crudGenericService.delete(attachmentAssociateUrl, payload.useQueryString ? undefined : { attachmentIds: payload.fileRequests.map(o => o.id) }).pipe(
      map(() => {
        payload.fileRequests.forEach(fileRequest => {
          const result = {
            ...fileRequest,
            ...{ secondsUntilTransferDialogShown: fileRequest.secondsUntilTransferDialogShown },
            ...{
              message: 'Removed',
              isError: false,
              percentComplete: 100,
              fileOperationType: FileOperationType.Remove,
              downloadLink: null
            }
          } as FileOperationInfo;
          this._store.dispatch(this._fileTransferObject.default.actions.updateFileUploadProgress({ payload: result }));
        });
        // Usually we return the original payload of the individual file but this is an action with array of file requests,
        // so we can't do that. It should be safe to return NULL here though - we don't really do anything meaningful with the result
        // (we just store it as "payload" of success action)
        return of(null);
      }),

      takeUntil(abort$),
      // This is slighlty modified code from childError custom rxjs operator.
      // We can't use child error as its supposed to operate on one request but this is a
      // compound request consisting of many deletions. So  instead we'll just borrow some of that code,
      // and tweak it a bit to add failed files
      catchError(error => {
        for (const fileRequest of payload.fileRequests) {
          this._failedObjectsService.addFailedObject(FAILED_OBJECT_FILE, {
            originalContent: fileRequest.originalContent,
            error,
            operation: CrudOperationType.Delete
          });
        }
        return throwError(() => error);
      })
    );
    return request;
  }
  private callUploadUpdate(uploadType: UploadType, payload: FileOperationUploadRequest) {
    const displayFilename = payload.displayFilename == null ? this.getFileNameForProgressDisplay(payload) : payload.displayFilename;
    const fileUploadUrl = uploadType === UploadType.New ? this.getUploadUrl(payload) : payload.file != null ? this.getUpdateUrl(payload) : this.getUpdateMetadataUrl(payload);

    const abort$ = super.getUploadAbortPipe(displayFilename, payload.actionId, payload);

    const endpoint =
      uploadType === UploadType.New
        ? this._fileTransferService.upload(
            fileUploadUrl,
            payload.file,
            payload.id,
            displayFilename,
            this._fileTransferObject,
            payload.parentId,
            payload.actionId,
            payload.metadata,
            this.shouldUseTus(payload),
            payload.originalContent
          )
        : this._fileTransferService.update(
            fileUploadUrl,
            payload.file,
            payload.id,
            displayFilename,
            this._fileTransferObject,
            payload.parentId,
            payload.actionId,
            payload.metadata,
            this.shouldUseTus(payload),
            payload.originalContent
          );

    const request = endpoint.pipe(takeUntil(abort$));
    return request;
  }
  upload(payload: FileOperationUploadRequest) {
    // This observable will check if there is already a file with this actionId
    // that has been queued and will return true or false
    const cancelCheck$ = this._store.select(this._fileTransferObject.default.selectors.fileOperations).pipe(
      take(1),
      map(filesFromStore => {
        if (filesFromStore.find(o => o.actionId == payload.actionId && o.percentComplete == 100)) {
          return false;
        }
        return true;
      })
    );
    let actionType = null;

    if (payload.id != null && payload.id != '0') {
      actionType = UploadType.Update;
    } else {
      actionType = UploadType.New;
    }
    return cancelCheck$.pipe(
      // Based on true or false returned from the call above we will either call the actual upload function (file not found)
      // or we'll immediately dispatch cancel
      mergeMap(o => {
        if (o) {
          return this.callUploadUpdate(actionType, payload);
        } else {
          return super.dispatchCancelMessage(payload.displayFilename, payload.actionId, payload, FileOperationType.Add);
        }
      })
    );
  }
  generateTempLink(payload: FileOperationDownloadRequest) {
    const link = this.getTempLinkGeneratorUrl(payload);
    return this._fileTransferService.generateTempLink(link, payload.fileName);
  }
  generateZipLink(o: FileOperationGenerateZipRequest): Observable<{ linkUrl: string; dateLinkExpires: string }> {
    const link = this.getZipLinkGeneratorUrl(o);

    return this._fileTransferService.generateZipLink(link, o.attachmentIds);
  }
  /**
   * @deprecated
   * @param url
   * @param fileName
   * @returns
   */
  previewByUrl(url: string, fileName = ''): Observable<HttpResponse<Blob> | HttpErrorResponse> {
    // eslint-disable-next-line deprecation/deprecation -- keeping this for now but also deprecting the previewByUrl method, so it's not used in the future
    return this.download({ isPreview: true, id: null, fileName, actionId: uuidv4(), secondsUntilTransferDialogShown: this._appConfig.uiSettings.file.previewDialogTimeLimitInSeconds }, url);
  }
  /**
   * @deprecated
   * @param payload
   * @param downloadUrlOverride
   * @returns
   */
  protected override download(payload: FileOperationDownloadRequest, downloadUrlOverride = null): Observable<HttpResponse<Blob> | HttpErrorResponse> {
    const actionId = payload.actionId ? payload.actionId : uuidv4();
    const abort$ = super.getDownloadAbortPipe(payload.fileName, actionId, payload);

    return this._fileTransferService
      .download(
        downloadUrlOverride, // ?? this.getDownloadUrl(payload),
        payload.id,
        payload.fileName,
        this._fileTransferObject,
        actionId,
        payload.secondsUntilTransferDialogShown,
        payload.isPreview,
        payload.headers
      )
      .pipe(
        takeUntil(abort$),
        filter((o: any) => o instanceof HttpResponse)
      )
      .pipe(
        catchError(error => {
          this.loadHeaders(error);
          this._store.dispatch(
            this._fileTransferObject.default.actions.processFileFail({
              payload: {
                secondsUntilTransferDialogShown: payload.secondsUntilTransferDialogShown,
                displayFilename: payload.displayFilename,
                downloadLink: null,
                fileName: payload.fileName,
                id: payload.id,
                percentComplete: 100,
                actionId,
                isError: true,
                message: this.getErrorMessage(error, true),
                fileOperationType: FileOperationType.Download,
                parentId: payload.parentId
              } as FileOperationInfo
            })
          );

          this._store.dispatch(
            reduxActionFail({
              error,
              payload,

              originalPayload: payload,
              reduxErrorOptions: {
                source: 'BaseEffect.download()',
                silent: true
              }
            })
          );

          return of(error);
        })
      );
  }
}
